<template>
  <section data-scroll-container>
    
    <!-- <div class="content"> -->
    <div id="profil" class=" position-relative" data-scroll-section>
      <div id="sticky-content" data-scroll data-scroll-sticky data-scroll-target="#profil">
        <p>{{ viewEvent.percentTop }} {{viewEvent.scrollPercent}}</p>
        <!-- image de Parfait -->
        <div class="position-absolute absolute-content">
            <img
              id="img-profil"
              class="profil"
              src="@/assets/images/import/pr/profil-parfait.png"
              alt="profil"
            />
        </div>
      </div>
      <!-- repère -->
      <div id="sticky-content-2">
        <div class="position-absolute absolute-content">
          <div class="profil-text mt-5" v-view="viewHandler" data-scroll-section>
            <div class="txt-1" data-scroll data-scroll-speed="5" data-scroll-direction="horizontal">Multilingue</div>
          </div>
        </div>
      </div>
      <div id="sticky-content-3">
        <div class="profil-text mt-5" v-view="viewHandler">
            <div class="txt-2"> Trouvez le <span>profil</span> <br> que vous <br> recherchez.</div>
        </div>
      </div>
      <div class="profil-text mt-5" v-view="viewHandler">
          <h4>CECI EST UN TEXT</h4>
      </div>
      <div class="profil-text mt-5" v-view="viewHandler">
          <h4>CECI EST UN TEXT</h4>
      </div>
    </div>

    <div id="section-2"  v-view class="row carrieres computer mt-0 px-0">
      <!-- car-1 -->
      <div class="col-sm-12  col-md-4   car car-1 d-flex justify-content-center flex-column align-self-center">
        <div class="title">
            <div class="text-center">
              <img style="height:100px" src="@/assets/images/import/pr/interview-640_white.png" alt="" srcset="">
          </div>
          <div class="text-center txt-carrieres Tangier_Medium">Recrutement</div>
        </div>
        <div class="text-hover">
          Identification des besoins clients
          Rédaction et la diffusion des offres d’emploi 
          Phases de sourcing (jobboard)
          Présélection des candidats, conduite des entretiens, présentation des candidats 
          Accompagnement en vue d’une prise de poste.
        </div>
      </div>
      <!-- car-2 -->
      <div class="col-sm-12  col-md-4 car car-2 d-flex justify-content-center flex-column align-self-center "  >
        <div class="title">
          <div class="text-center">
              <img class="icon" src="@/assets/images/import/pr/gestion carrière.png" alt="" srcset="">
          </div>
          <div class="text-center txt-carrieres Tangier_Medium">Gestion carrières et compétences
          </div>
        </div>
          <div class="text-hover">
            Management des emplois et des compétences
          Politique de la formation
          Accompagnement & suivi de l'évolution 
          Développement de la mobilité professionnelle
        </div>
      </div>
      <div class="col-sm-12  col-md-4 car car-3 d-flex justify-content-center flex-column align-self-center">
        <div class="title">
          <div class="text-center ">
              <img class="icon " src="@/assets/images/import/pr/gestion_administrative.png" alt="" srcset="">
          </div>
          <div class="text-center  txt-carrieres Tangier_Medium">Gestion administrative du personnel</div>
        </div>
        <div class="text-hover">
            Application des obligations légales, conventionnelles ou règlementaires
            Formalités et documents liés à l’embauche
            Registres obligatoires
            Formation de contrat de travail
            Procédures de fin de contrat
        </div>
      </div>
    </div>
    <div class="content">
      <div  class="row carrieres-mobile m-2 mobile"  >
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2 p-4  car-mobile car-1 d-flex justify-content-center flex-column align-self-center">
          <div class="title-mobile">
              <div class="text-center">
                <img style="height:100px" src="@/assets/images/import/pr/interview-640_white.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium">Recrutement</div>
          </div>
          <div class="text-1">
            Identification des besoins clients
            Rédaction et la diffusion des offres d’emploi 
            Phases de sourcing (jobboard)
            Présélection des candidats, conduite des entretiens, présentation des candidats 
            Accompagnement en vue d’une prise de poste.
            </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2 p-4  car-mobile car-2 d-flex justify-content-center flex-column align-self-center "  >
          <div class="title-mobile">
            <div class="text-center">
                <img class="icon" src="@/assets/images/import/pr/gestion carrière.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium">Gestion carrières et compétences
            </div>
          </div>
           <div class="text-1">
             Management des emplois et des compétences
            Politique de la formation
            Accompagnement & suivi de l'évolution 
            Développement de la mobilité professionnelle
            </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2 p-4  car-mobile car-3 d-flex justify-content-center flex-column align-self-center">
          <div class="title-mobile">
            <div class="text-center ">
                <img class="icon " src="@/assets/images/import/pr/gestion_administrative.png" alt="" srcset="">
            </div>
            <div class="text-center  txt-carrieres Tangier_Medium">Gestion administrative du personnel</div>
          </div>
          <div class="text-1">
              Application des obligations légales, conventionnelles ou règlementaires
              Formalités et documents liés à l’embauche
              Registres obligatoires
              Formation de contrat de travail
              Procédures de fin de contrat
          </div>
        </div>
      </div>

      <!-- card / text / fond image -->
      <div v-view="cardHandler"   class="col-12 computer  text-center card-horizontal position-relative" data-aos="fade-up" data-aos-duration="1500">
        <input  class="input-range blue-card-range" orient="vertical" type="range" v-model="profilKey" min="1" max="45" disabled>
        <input  class="input-range orange-card-range" orient="vertical" type="range" v-model="profilKey" min="1" max="45" disabled>
        <transition name="fade">
          <div v-if="profilKey > 9  && profilKey < 19">
            <div :style="`--orangescroll:${orangescroll}%`" class="orange-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="1500">
              <div v-if="profilKey >= 10" class="card-text-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div v-if="profilKey >= 12" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">IT/ DIGITAL</div>
              <div v-if="profilKey >= 14" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">COMMERCIAL</div>
              <div v-if="profilKey >= 16" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">AGROALIMENTAIRE</div>
              <div v-if="profilKey >= 18" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">RETAIL</div>
            </div>
          </div>
        </transition>
          
          <!-- <div style="height:300px"></div> -->
      <!-- </div> -->

      <!-- <div class="col-12 text-center card-horizontal m-4" data-aos="fade-in" data-aos-duration="1500" > -->

          <div >
            <div v-if="profilKey >= 22 && profilKey < 33" :style="`--bluescroll:${bluescroll}%`"  class="blue-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="3000">
              <div v-if="profilKey >= 24" class="card-text-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div v-if="profilKey >= 26" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">EBP PAIE</div>
              <div v-if="profilKey >= 28" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">SAGE PAIE</div>
              <div v-if="profilKey >= 30" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">HUMANSOURCING</div>
              <div v-if="profilKey >= 32" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">CEGID</div>
            </div>
            <!-- <input  class="input-range orange-card-range" orient="vertical" type="range" v-model="profilKey" min="1" max="45"> -->
          </div>

          <div v-if="profilKey >= 34">
            <div v-if="profilKey >= 36" :style="`--bluescroll:${bluescroll}%`" class=" orange-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="3000">
              <div v-if="profilKey >= 38" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je parle l'anglais, le français,le malgache</div><br>
              <div  v-if="profilKey >= 40" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je me déplace sur toute la France</div><br>
              <div  v-if="profilKey >= 42" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je travaille aisément en télétravail </div><br>
            </div>
            <!-- <input  class="input-range orange-card-range" orient="vertical" type="range" v-model="profilKey" min="1" max="45"> -->
          </div>

        <img id="points-image" class="position-absolute computer" src="@/assets/images/import/pr/points.png" alt="">
      </div>

      <div   class="col-12 mobile text-center card-horizontal-mobile " data-aos="fade-up" data-aos-duration="1500">
          <div  >
            <div class="orange-card-mobile  d-flex flex-column justify-content-center"  data-aos="fade-in" data-aos-duration="1500">
              <br>
              <div  class="card-text-mobile-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">IT/ DIGITAL</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">COMMERCIAL</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">AGROALIMENTAIRE</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">RETAIL</div>
            </div>
          </div>
      </div>

      <div class="col-12 text-center mobile card-horizontal-mobile" data-aos="fade-in" data-aos-duration="1500" >
          <div>
            <div  class="blue-card-mobile  d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="1500">
              <br>
              <br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je parle l'anglais, le français,le malgache</div><br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je me déplace sur toute la France</div><br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je travaille aisément en télétravail </div><br>
            </div>
          </div>           
      </div>

      <div id="section-4"  v-view class="col-12 text-center contact-card computer" data-aos="fade-in" data-aos-duration="3000">
          <p class="card-text-1">TPE, PME, Grands groupes,<br> déléguez le recrutement à un spécialiste !</p>
          <button class="pr-btn" @click="goContact()">Contactez-moi !</button>
      </div>

      
      <div v-view class="col-12 text-center contact-card mobile" data-aos="fade-in" data-aos-duration="3000">
          <p class="card-text-mobile-1">TPE, PME, Grands groupes,<br> déléguez le recrutement à un spécialiste !</p>
          <button class="pr-btn" @click="goContact()">Contactez-moi !</button>
      </div>
    </div>
  </section>
</template>
<script>
import LocomotiveScroll from 'locomotive-scroll';
export default {
  name: "home2",
  components: {},
  data() {
    return {
      profilKey: 0,
      AnchorProfilKey:[
        {value:1, link:"#profil"}
      ],
      cardKey:0,
      orangeCard:true,
      orangescroll:40,
      bluescroll:40,
      profilScroll2:true,
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0
      }
    };
  },
  computed:{
    // scrollPercent(){
    //   // return this.onceHandler(e.percentCenter)
    // }
  },
  watch:{
    // profilScroll2(value){
    //   if(value==true){
    //     this.scrollOp(true)
    //     window.addEventListener('wheel', this.profilScroll)
    //   }
    // }
  },
  methods: {
    goContact(){
      this.$router.push({
          name:'contact'
      })
    },
    viewHandler (e) {
      if (e.type === 'exit') return
      Object.assign(this.viewEvent, e)
    },
    // onceHandler (e) {
    //   this.onceTime = new Date()
    // }
  },
  mounted() {
    console.log(this.$device.mobile);
    // document.getElementById("test").focus()
    // this.scrollOp(true)
    

  const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true
  });
  console.log(scroll);
  },
};
</script>
<style scoped>

.content {
  min-height: 600px;
  max-width: 80vw;
  /* padding: 20px; */
  margin: auto;
}

@media screen and (max-width: 992px) {
  /**MOBILE STYLE */

  /**profile section style */
  .profil{
    position: absolute;
    z-index: 1;
    width: 50%;
    top:20px;
    left: 5%;
  }
  .profil-container{
    height: 300px;
    position: relative;
  }

  .rayures {
    position: absolute;
    z-index: -1;
    top: 26%;
    width: 80%;
    right: 0px;
  }

  .txt-1 {
    top: 50px;
    left: 30%;
    text-transform: uppercase;
    font-size: 3rem;
    color: rgb(187, 185, 185);
  }

  .txt-2 {
    top: 30px;
    left: 50%;
    font-family: "Noto Sans";
    color: black;
    font-weight: 600;
    font-size: 1.5rem;
  }
  /***************************/

  /** CARRIERE */
  .carrieres > div{
    min-height: 390px;
    /* min-height: 33rem !important; */
    /* min-height: 30vw; */
  }

  .title{
    margin-top: 50px;
  }

}

@media screen and (min-width: 992px) {
  /**COMPUTER STYLE */
  /**profile section style */
  #profil{
    min-height: 300vh;
    background: brown;
  }
  #sticky-content{
    position: sticky;
    position: -webkit-sticky;
    width: 90%;
    top: 0px;
    min-height: 100vh;
    background: rgba(21, 185, 21, 0.434);
  }
  #sticky-content-2{
    position: sticky;
    position: -webkit-sticky;
    width: 90%;
    top: 50px;
    min-height: 100vh;
    background: rgba(39, 202, 239, 0.434);
  }
  #sticky-content-3{
    position: sticky;
    position: -webkit-sticky;
    width: 90%;
    top: 150px;
    min-height: 100vh;
    background: rgba(255, 208, 90, 0.434);
  }
  .absolute-content{
    width: 100%;
  }
  #img-profil{
    width: 50%;
    z-index: 1;
    height: 100vh;
    bottom: 0;
    left: 14%;
  }
  .profil-text{
    height: 100vh;
  }

  #section-1{
    height: 100vh;
  }
  .profil-container {
    height: 80vh ;
    position: relative;
  }
  
  .profil {
    /* position: absolute; */

    z-index: 1;
    /* height: 85vh; */
    height: 200vh;
    bottom: 0;
    left: 14%;
  }

  .rayures {
    position: absolute;
    z-index: -1;
    top: 26%;
    height: 71vh;
    right: 0px;
    width: 70vw;
  }

  .txt-1 {
    top: 25%;
    left: 28%;
    text-transform: uppercase;
    font-size: 11vw;
    color: rgba(21, 22, 80, 0.3);
  }


  .txt-2 {
    top: 38%;
    left: 48%;
    font-family: "Noto Sans";
    color: black;
    font-weight: 600;
    font-size: 5vw;
    /* width: 38rem; */
  }
  /***************************/

  /** CARRIERE */
  .carrieres > div {
    /* height: 390px; */
    /* height: 33rem; */
    height: 30vw;
  }

  .title{
    transition:all 0.5s ease-in-out;
    /* margin-top:40%; */
  }
  .profil-range{
    height: 80%;
    position: absolute;
    bottom: 1rem;
    left: 2rem;
  }

  /* animation hover car*/

  #section-2 .text-hover{
    transition:all 1s linear;
    height: 0px;
    animation-name: card
  }
  #section-2 .car:hover .text-hover{
    /* height: auto; */
    animation: card 5s ease-in-out forwards;
  }
  @keyframes card {
    0%   {height: 0%;}
    100%  {height: auto;}
  }
} /*end of media query*/ 

.txt-1,
.txt-2 {
  position: absolute;
}

.txt-2 span {
  color: #f93a00;
}

.carrieres {
  padding-left: 80px;
  padding-right: 80px;
}

.icon{
  width: 90px;
}

.card-horizontal{
  padding-left: 10%;
  padding-right: 10%;
  height: 400px;
}

.card-horizontal-mobile{
  padding: 20px;
}
.car-1 {
  background-image: linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-1.jpg");
  background-size: cover;
}
.car-1:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-1.jpg"); 
  
}
.car:hover .text-hover{
  transition:all 0.1s;
  visibility: visible;
  margin-bottom:  0rem;
  opacity: 1;
}



.car:hover .title{
  margin-top:0%;

}

.car .text-hover{
  /* display: none; */
  /* margin-top: 15rem; */
  visibility: hidden;
  transition: all 2s;
  opacity: 0;
}

.car-2 {
  background-color: #876d55;
  background-image:   linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-2.jpg");
  background-size: cover;
}
.car-2:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-2.jpg");
}
.car-3 {
  background-color: #876d55;
  background-image: linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-3.jpg");
  background-size: cover;
}
.car-3:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-3.jpg");
}

.txt-carrieres{
  /* font-family:'Script MT Bold'; */
  font-size: 38px;
  font-weight: 500;
  color: white;
}
.blue-card{
    /* position: sticky; */
    /* position: -webkit-sticky; */
    /* top: 0; */
    height: 300px;
    background-color: #6295FE;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    margin-top: 0px;
    background-image:  url("../../assets/images/import/pr/forest_blue.png");
    background-size: var(--bluescroll);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    /* transition: background-size 0.3s ease; */

}

.blue-card-mobile{
    min-height: 300px;
    background-color: #6295FE;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    margin-top: 0px;
    background-image:  url("../../assets/images/import/pr/forest_blue.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
}
.orange-card{
    /* position: -webkit-sticky; */
    /* position: sticky; */
    /* top: 0; */
    height:300px;
    background: #f93a00;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    border-radius: 3px;
    background-image:  url("../../assets/images/import/pr/forest-_orange_degrade.png");
    background-size: var(--orangescroll);
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    /* transition: background-size 0.3s ease; */
    
}

.orange-card-mobile{
  
    min-height:300px;
    background-color: #FB6E00;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
     border-radius: 3px;
    background-image:  url("../../assets/images/import/pr/forest-_orange_degrade.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
}
.card-text-1{
      font-family:'Noto Sans';
      font-size: 2rem;
}

.card-text-2{
      font-family:'Noto Sans';
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      line-height: 40px;

}

.card-text-mobile-1{
      font-family:'Noto Sans';
      font-size: 1.5rem;
}

.card-text-mobile-2{
      font-family:'Noto Sans';
      font-size: 1.5rem;
      font-weight: 500;
      color: #fff;
      line-height: 30px;

}

.contact-card{
  /* margin-top: 100px; */

  margin-bottom: 200px;
}



.text-hover{
  font-family: "Noto Sans";
  color:#fff;
  text-align: center;
  line-height: 40px;
}
.text-1{
  font-family: "Noto Sans";
  color:#fff;
  text-align: center;
  line-height: 30px;
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  /* margin-top: 0; */
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  /* margin-bottom: 300px; */
}
.pr-btn{
 /* background: linear-gradient(90deg, rgba(250,88,0,1) 0%, rgba(253,186,0,1) 100%); */
 background-image:linear-gradient(to right, #fa5800, #fdba00);
 background-size: 10rem 12rem;
 background-position: center;
 /* background-clip: padding-box; */
 color: white;
 font-family: "Noto Sans";
 font-weight: 600;
 padding: 10px;
 border: solid 5px transparent;
 border-radius: 2em;
}
.pr-btn:hover{
  border: solid 5px transparent;
  background-image: linear-gradient(white,white), linear-gradient(to right, #fa5800, #fdba00);
  color: gray;
  font-family: "Noto Sans";
  background-clip: padding-box, border-box;
  background-origin: border-box;
}

.input-range {
    -webkit-appearance: none;
    height: 200px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #706d7021;
    border-radius: 25px;
    border: 0px solid #000101;
    width: 3px;
    outline: none;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    transform: rotate(180deg) scaleX(-1);
    cursor: pointer;
    scroll-behavior: smooth;
    
  }
  .profil-range{
    height: 80%;
    position: absolute;
    bottom: 1rem;
    left: 4rem;
    /* height: 100%;
    margin-left: 20px; */
  }

  .blue-card-range{
    position: absolute;
    right: 0;
    /* top: 50px; */
    height: 40vh;
  }

   .orange-card-range{
    position: absolute;
    left: 0;
    top: 30vh;
    height: 50vh;
  }

  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #F93A00;
    height: 3rem;
    width: 5px;
}
#points-image{
  bottom: 60px;
  height: auto;
  width: 15rem;
  right: 2rem;
}
.input-range:hover{
cursor: initial !important;
}
</style>